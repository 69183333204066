import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Helmet, Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

const PortfolioItemTemplate = ({
  pageContext: { portfolioDef, portfolioImgData },
}: any) => {
  const { t } = useTranslation();

  return portfolioDef ? (
    <>
      <Helmet>
        <title>{t(portfolioDef.titleKey)} | weebee</title>
      </Helmet>
      <Link
        className="fixed top-0 bottom-0 left-0 right-0 z-50 overflow-scroll bg-[#222848] text-center"
        to="/"
      >
        <GatsbyImage
          className="mx-auto"
          image={portfolioImgData}
          alt={t(portfolioDef.titleKey)}
        />
      </Link>
    </>
  ) : null;
};

export default PortfolioItemTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
